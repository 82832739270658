<template>
  <div class="flex">
    <div class="flex justify-between flex-1">
      <button
        v-if="
          $router?.currentRoute?.value?.name !==
            'hq-HqMonitoringOfPracticeFunctionsPatientSurvey' &&
          $router?.currentRoute?.value?.name !== 'ma-MOPFPatientSurveyDash' &&
          $router?.currentRoute?.value?.name !==
            'hq-monitoringOfPracticeFunctionsQofDashboard' &&
          $router?.currentRoute?.value?.name !== 'ma-QofDash'
        "
        @click="$router.go(-1)"
        class="bg-transparent border-teal border w-24 h-10 text-teal rounded-md hover:bg-teal hover:text-white"
      >
        Go Back
      </button>
      <div
        v-if="
          $router?.currentRoute?.value?.name ===
            'hq-HqMonitoringOfPracticeFunctionsPatientSurvey' ||
          $router?.currentRoute?.value?.name === 'ma-MOPFPatientSurveyDash' ||
          $router?.currentRoute?.value?.name ===
            'hq-monitoringOfPracticeFunctionsQofDashboard' ||
          $router?.currentRoute?.value?.name === 'ma-QofDash'
        "
      ></div>
      <button
        class="bg-transparent border-teal border hover:shadow-lg hover:bg-teal hover:text-white w-12 h-10 font-light text-teal text-center rounded-md"
      >
        <span class="material-icons ml-3 mt-1"
          >notifications_none_outlined</span
        >
      </button>
    </div>
  </div>
</template>

<script setup></script>
